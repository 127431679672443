<template>
	<div>
		<div class="analysis">
			<div class="echarts">
				<h3 class="tit">车辆进场情况</h3>
				<histogram id="lineInPark" type="line"  :data="lineInPark"></histogram>
			</div>
			<div class="echarts">
				<h3 class="tit">车辆出场情况</h3>
				<histogram id="lineOutPark" type="line"  :data="lineOutPark"></histogram>
			</div>
<!--            v-if="showParkingRate"-->
			<div class="echarts">
				<h3 class="tit">车辆停车率</h3>
				<histogram id="parkRate" type="line"  :data="parkRate"></histogram>
			</div>
			<div class="echarts">
				<h3 class="tit">停车场收支金额</h3>
				<histogram id="earnings" type="bar"  :data="earnings"></histogram>
			</div>
		</div>
		<div class="right">
			<div class="count">
				<h3 class="tit">会员类型</h3>
				<h2 class="titPark">进场:</h2>
				<echartscom id="periodIn" :nameData="entryCarTimes.rows"
										:chartData="entryCarTimes.chartData"></echartscom>
				<h2 class="titPark">出场:</h2>
				<echartscom id="periodOut" :nameData="outCarTimes.rows"
										:chartData="outCarTimes.chartData"></echartscom>
			</div>
			<div class="count">
				<h3 class="tit">数值统计</h3>
				<div class="number countAmount">
					<div>
						<p class="inPark">{{ countByTimes.inPark }}</p>
						<span>共计进场频次</span>
					</div>
					<div>
						<p class="outPark">{{ countByTimes.outPark }}</p>
						<span>共计出场频次</span>
					</div>
					<div>
						<p class="parkTime">{{ countByTimes.incomeAmount }}</p>
						<span>收入金额(元)</span>
					</div>
					<div>
						<p class="loss">{{ countByTimes.lossAmount }}</p>
						<span>总亏损金额(元)</span>
					</div>
					<div>
						<p class="lossCoupon">{{ countByTimes.lossCoupon }}</p>
						<span>优惠券亏损金额(元)</span>
					</div>
				</div>
			</div>
		</div>

	</div>

</template>

<script>
export default {
	name: "areaPeriod",
	props: {
		parkRate: {
			type: Object
		},
    lineInPark: {
      type: Object
    },
    lineOutPark: {
      type: Object
    },
    earnings: {
      type: Object
    },

    entryCarTimes: {
      type: Object
    },

    outCarTimes: {
      type: Object
    },
    countByTimes: {
      type: Object
    },
		showParkingRate:{
			type: Boolean
		}
	},
	data() {
		return {
		};
	},
	mounted() {
	},
	watch:{
		showParkingRate: {
			handler(newval) {
				console.log(newval)
			}
		},
	},
	methods: {},
	components: {
		"echartscom": () => import("../account/echartscom"),
		"histogram": () => import("./histogram"),
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/statistics";

.analysis {
	width: 65%;
}

.right {
	width: calc(100% - 67%);
	float: left;
	margin-left: 18px;

	.count {
		width: auto;
		float: none;
		margin-left: 0;
	}
}

</style>

